<template>
  <div class="main">
    <div class="top">
      <p class="top_left">
        <span>批量录入</span>
      </p>
      <p class="top_right"><span class="xinxin">*</span> 为必填项</p>
    </div>
    <el-divider></el-divider>

    <div class="conten">
      <el-form
        :model="regitsterInfo"
        :rules="regitsterInfoRules"
        ref="regitsterInfoForm"
        label-width="180px"
      >
        <!-- 任务信息 -->
        <div class="register-info">
          <div class="title">
            <span class="blue"></span>
            <p style="font-size: 16px">任务信息</p>
          </div>
          <!-- {{regitsterInfo}} -->
          <div class="content-item">
            <el-form-item
              class="formitem"
              label="任务来源："
              prop="taskSourceCode"
            >
              <el-cascader
                class="select-width"
                ref="taskSourceRef"
                v-model="regitsterInfo.taskSourceCode"
                :props="{ ...props, checkStrictly: true }"
                @change="handleTaskSource"
                clearable
              ></el-cascader>
            </el-form-item>

            <el-form-item class="formitem" label="信息类别：" prop="infoType">
              <div class="ph">
                <el-radio-group
                  v-model="regitsterInfo.infoType"
                  class="select-width2ax"
                >
                  <el-radio
                    v-for="item in infoType"
                    :label="item.value"
                    :key="item.value"
                    >{{ item.label }}</el-radio
                  >
                </el-radio-group>
                <el-input
                  v-show="regitsterInfo.infoType == 3"
                  class="other-input"
                  v-model="regitsterInfo.infoTypeOther"
                  placeholder="请输入其他信息类别"
                ></el-input>
                <el-input
                  v-show="regitsterInfo.infoType == 1"
                  class="other-input"
                  v-model="regitsterInfo.letterNo"
                  placeholder="协查函编号"
                  :maxlength="32"
                ></el-input>
              </div>
            </el-form-item>
          </div>

          <div class="content-item">
            <el-form-item class="formitem" label="事件名称：" prop="eventName">
              <el-select
                class="select-width"
                v-model="regitsterInfo.eventId"
                clearable
                filterable
                placeholder="请输入事件名称"
                @change="issueName"
              >
                <el-option
                  v-for="item in eventNameSelect"
                  :key="item.id"
                  :label="item.eventName"
                  :value="item.id"
                  @click.native="ldcs(item)"
                ></el-option>
              </el-select>
            </el-form-item>

            <el-form-item class="formitem" label="调查场所：">
              <el-select
                class="select-width"
                v-model="regitsterInfo.surveyAddress"
                clearable
                placeholder="请选择调查场所"
              >
                <el-option
                  v-for="item in eventcsSelect"
                  :key="item.id"
                  :label="item.placeName"
                  :value="item.placeName"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>

          <div class="content-item">
            <el-form-item
              class="special"
              label="任务描述："
              prop="surveyResultDes"
            >
              <el-input
                type="textarea"
                v-model="regitsterInfo.taskRemark"
                placeholder="请输入内容"
                maxlength="200"
                show-word-limit
                class="select-width"
              ></el-input>
            </el-form-item>
          </div>
          <div class="content-item">
            <el-form-item class="special" label="附件:">
              <el-upload
                class="select-width"
                :action="upload"
                accept=".jpg, .png, .doc, .docx, .pdf, .xls, .xlsx"
                :before-upload="beforeUpload"
                :on-preview="handlePreview"
                :before-remove="beforeRemove"
                :on-remove="handleRemove"
                :on-success="handelSuccess"
                :on-error="handelError"
                :headers="myHeaders"
                :file-list="fileList"
              >
                <el-button
                  size="small"
                  icon="el-icon-upload"
                  >上传文件</el-button
                >
                <span slot="tip" class="el-upload-tip">
                  支持格式：.jpg .png .doc .docx .pdf .xls
                  .xlsx，单个文件不能超过20MB
                </span>
              </el-upload>
            </el-form-item>
          </div>
        </div>

        <!-- 流调信息 -->
        <div class="register-info">
          <div class="title">
            <span class="blue"></span>
            <p style="font-size: 16px">流调信息</p>
          </div>
          <!-- {{regitsterInfo}} -->
          <div class="content-item">
            <el-form-item
              class="formitem"
              label="调查人员："
              prop="surveyUserName"
            >
              <!-- <el-select
                v-model="regitsterInfo.surveyUserName"
                class="select-width"
              >
                <el-option
                  v-for="item in surveyUserNameSelect"
                  :key="item.id"
                  :label="item.name"
                  :value="item.name"
                  @click.native="handleSurveyUserName(item)"
                ></el-option>
              </el-select> -->
              <el-input
                disabled
                v-model="regitsterInfo.surveyUserName"
                placeholder=""
                class="select-width"
              ></el-input>
            </el-form-item>
            <el-form-item class="formitem" label="调查时间：" prop="surveyDate">
              <el-date-picker
                class="select-width"
                v-model="regitsterInfo.surveyDate"
                type="datetime"
                placeholder="选择时间"
                format="yyyy-MM-dd HH:mm"
                value-format="yyyy-MM-dd HH:mm"
              ></el-date-picker>
            </el-form-item>
          </div>

          <div class="content-item">
            <div class="classify">
              <el-form-item
                class="formitem"
                label="人群分类："
                prop="crowdTypeId"
              >
                <el-select
                  class="select-width"
                  filterable
                  clearable
                  v-model="regitsterInfo.crowdTypeId"
                  placeholder="选择人群分类"
                >
                  <el-option
                    v-for="item in typepopSelect"
                    :key="item.id"
                    :label="item.ruleName"
                    :value="item.id"
                    @click.native="handelCrowdType(item)"
                  ></el-option>
                </el-select>

                <el-input
                  v-show="regitsterInfo.crowdType == 9"
                  class="other-input"
                  v-model="regitsterInfo.otherCrowdTypeName"
                  placeholder="请输入其他人群"
                  :maxlength="32"
                  width:500
                ></el-input>
              </el-form-item>
            </div>

            <el-form-item class="formitem" label="次要人群分类：">
              <el-select
                class="select-width"
                value-key="code"
                collapse-tags
                @change="selectchange($event)"
                v-model="selectArr"
                multiple
                placeholder="请选择"
              >
                <el-option
                  v-for="item in typepopSelect"
                  :key="item.id"
                  :label="item.ruleName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>

          <div class="content-item1">
            <el-form-item
              class="special1"
              label="流调结果："
              prop="surveyResult"
            >
              <div class="ph">
                <el-radio-group
                  v-model="regitsterInfo.surveyResult"
                  @change="ishome"
                  class="select-width2ax"
                >
                  <el-radio
                    v-for="item in surveyResult"
                    :label="item.value"
                    :key="item.value"
                    >{{ item.label }}
                  </el-radio>
                </el-radio-group>
              </div>
              <div v-if="regitsterInfo.surveyResult == 4">
                <el-input
                  class="select-width2"
                  v-model="regitsterInfo.otherResult"
                  placeholder="请输入其他类型"
                ></el-input>
              </div>
            </el-form-item>
          </div>
          <div class="content-item">
            <el-form-item
              prop="surveyObjectBelongOrg"
              class="formitem"
              label="负责机构："
              v-if="
                regitsterInfo.surveyResult == 0 ||
                regitsterInfo.surveyResult == 2
              "
            >
              <el-select
                class="top_left_inputnationality select-width"
                filterable
                v-model="regitsterInfo.surveyObjectBelongOrg"
                placeholder="请选择"
                clearable
              >
                <el-option
                  v-for="item in homeOrgList"
                  :key="item.orgCode"
                  :label="item.orgName"
                  :value="item.orgName"
                  @click.native="handelSurveyObjectBelongOrg(item)"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              class="formitem"
              label="负责机构："
              v-if="regitsterInfo.surveyResult == 3"
              prop="surveyObjectBelongOrg"
            >
              <el-select
                class="top_left_inputnationality select-width"
                filterable
                v-model="regitsterInfo.surveyObjectBelongOrg"
                placeholder="请选择"
                clearable
              >
                <el-option
                  v-for="item in concentrateOrgList"
                  :key="item.orgCode"
                  :label="item.orgName"
                  :value="item.orgName"
                  @click.native="handelSurveyObjectBelongOrg(item)"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="content-item">
            <el-form-item class="special" label="说明：" prop="surveyResultDes">
              <el-input
                type="textarea"
                v-model="regitsterInfo.surveyResultDes"
                placeholder="请输入内容"
                maxlength="200"
                show-word-limit
                class="select-width"
              ></el-input>
            </el-form-item>
          </div>
        </div>

        <!-- 居民信息 -->
        <div class="register-info">
          <!-- 居民信息 -->
          <resident-basic-info
            key="residentBasicInfo"
            ref="residentBasicInfoRef"
            :myHeaders="myHeaders"
            :surveyResult="regitsterInfo.surveyResult"
          >
          </resident-basic-info>
        </div>
        <br>

        <!-- 7为次密（一密） -->
        <template v-if="regitsterInfo.crowdType == 7">
          <relate-close-contact
            @father="father"
            ref="closeContactRef"
            :addtype="this.type"
            :crowdType="regitsterInfo.crowdType"
          >
          </relate-close-contact>
        </template>

        <!-- 阳性 -->
        <template :v-show="!!regitsterInfo.crowdType">
          <RelatePositiveCase
            @father102="father102"
            ref="positiveCaseRef"
            :addtype="this.type"
            :crowdType="regitsterInfo.crowdType"
          >
          </RelatePositiveCase>
        </template>
      </el-form>
    </div>
    <div class="btnbox">

    </div>
    <div class="btns">
      <el-button type="primary" size="medium" :loading="submitLoading" @click="submitForm">提交</el-button>
      <!-- <el-button @click="resetForm">重置</el-button> -->
    </div>
    <!-- 提交对话框（警告框） -->
    <el-dialog
      title="警告提示"
      :visible.sync="dialogVisible"
      width="40%"
      :before-close="closeDialog"
    >
      <el-row class="warning" align="middle">
        <i
          class="el-icon-warning-outline"
          style="
            color: #ff9900;
            font-size: 26px;
            font-weight: bold;
            margin-right: 10px;
          "
        ></i>
        <span style="font-weight: bold; font-size: 16px"
          >当前保存用户疑似已入住隔离，请核实情况</span
        >
        <br />
        <br />

        <div
          class="notification"
          v-for="(item, index) in dialogMessage"
          :key="index"
        >
          <div class="NameNotification">
            {{ item.name }}
          </div>
          <div class="CardIdNotification">
            {{ item.cardNo }}
          </div>
          <div class="OrgPhoneNotification">
            {{ item.org }}({{ item.linkPhone ? item.linkPhone : "-" }})
          </div>
          <div class="TypeNotification">
            <span v-if="item.type == '隔离'"
              ><el-tag size="mini">隔离</el-tag></span
            >
            <span v-else><el-tag type="danger" size="mini">流调</el-tag></span>
          </div>
        </div>

      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" :loading="continueSubmitLoading" @click="postSubmit">继续提交</el-button>
        <el-button @click="closeDialog">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { _debounce } from "@/utils/utils.js";
import { http } from "@/api/index";
import {
  saveSurveyInfoList, //批量录入保存
  batchCheckIsReg, //批量录入查重
  getEventListApi,
  // updateSurveyInfoApi,
  // getRegLiveUnSurveyUserList,
  getPlaceList,
  // getSurveyGroupUserListApi,
  // getRegById,
  // relationList,
  // checkIsRegApi,
} from "@/api/DiseaseControlManagement/DiseaseControlManagement";
import { getToken } from "@/utils/auth";
import { getBusinessOrgList } from "@/api/SystemManagement/index"; //根据业务状态查询机构列表
import { getCrowdTypeList } from "@/api/RuleManagement/crowdAnalysis";
import {
  getMedicalOrgList,
  getUserListByPeopleTypeApi,
} from "@/api/SystemManagement/index";
// import { configKey } from "@/api/SystemManagement/isolationpointmanagement";
// import constants from "@/common/constants";
import { SURVEY_RESULT, INFO_TYPE } from "@/common/constants";
import RelatePositiveCase from "../EpidemiologicalSurveyRegister/component/RelatePositiveCase.vue";
import RelateCloseContact from "../EpidemiologicalSurveyRegister/component/RelateCloseContact.vue";
import ResidentBasicInfo from "./modules/ResidentInfo.vue";
import DialogInfo from "@/components/DialogInfo.vue";
import { isIDCard, isPhone } from "@/utils/validate";
import { mapState,mapMutations } from "vuex";
import {
  // getIdNumberInfo,
  // downloadExportFile,
  // importFile,
  importUrlFile,
} from "@/utils/utils";
import { Loading } from "element-ui";
// import { number } from 'echarts';

export default {
  components: {
    RelatePositiveCase,
    RelateCloseContact,
    DialogInfo,
    ResidentBasicInfo,
  },
  data() {
    const phoneCheck = (rule, value, callback) => {
      if (!isPhone(value)) {
        callback(new Error("请输入正确格式的手机号"));
      } else {
        callback();
      }
    };
    // 需要搬到residentinfo里面
    const cardNoCheck = (rule, value, callback) => {
      if (!isIDCard(value) && this.regitsterInfo.cardType == "1") {
        callback(new Error("请输入正确格式的身份证号"));
      } else {
        callback();
      }
    };

    return {
      continueSubmitLoading:false,
      submitLoading:false,
      type: "add",
      // checkType: "",
      isReg: [], // 查重人员数组
      selectArr: [],
      concentrateOrgList: [],
      homeOrgList: [],
      // title: "流调登记表",
      // echoAddress: false,
      // cgldregId: "",
      myHeaders: {
        jtoken: getToken(),
        clientType: "ADMIN",
        userType: "USER_DOCTOR",
      }, //上传文件请求头
      upload: "/ohealth/api/v1/system/common/uploadFile", //文件上传地址
      dialogVisible: false, //查重对话框
      // preTaskStatus: "", //任务状态
      // hxpd: false,
      fileList: [], //回显时文件列表
      tempFileList: [], //备份文件列表
      // typepopSelect11: [], //关联已入住人员
      eventcsSelect: [],
      dialogMessage: {}, //对话框
      surveyUserNameSelect: [], //调查人员项
      eventNameSelect: [], //事件名称项
      
      surveyObjectBelongOrgSelect: [], //所属社区
      infoType: INFO_TYPE, //信息类别数组
      regitsterInfo: {
        infoType: "1", //信息类别：默认协查函
        taskRemark: "", //任务描述说明
        secondaryCrowdTypes: [], //次要人群分类多选
        // glrz: "", //关联已入住人员
        otherResult: "", //请输入其他类型
        taskSource: "", //任务来源地址
        taskSourceCode: [], //任务来源编码
        surveyUserName: JSON.parse(localStorage.getItem("userdoctor")).nickName,
        surveyUserId: JSON.parse(localStorage.getItem("userdoctor")).id,
        eventId: "", //事件名称id
        eventName: "", //事件名称name
        surveyDate: new Date(), //选择调查时间，默认当前
        surveyAddress: "", //调查场所
        surveyResult: "3",
        surveyResultDes: "", //流调信息说明

        crowdType: "",
        crowdTypeName: "",
        crowdTypeId: "",
        otherCrowdTypeName: "",
        // surveyObjectBelongArea: "",
        // surveyObjectBelongAreaCode: [],
        surveyObjectBelongOrg: "",
        surveyObjectBelongOrgCode: "",
        taskDesc: "",
      },
      typepopSelect: [],
      regitsterInfoRules: {
        taskSourceCode: [
          { required: true, message: "请选择任务来源", trigger: "change" },
        ],
        surveyUserName: [
          { required: true, message: "请选择调查人员", trigger: "change" },
        ],
        surveyDate: [
          { required: true, message: "请选择调查时间", trigger: "change" },
        ],
        crowdTypeId: [
          { required: true, message: "请选择人群分类", trigger: "change" },
        ],
        surveyAddress: [
          { required: true, message: "请输入调查场所", trigger: "blur" },
        ],
        surveyResult: [
          { required: true, message: "请选择流调结果", trigger: "change" },
        ],
        // name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        // cardType: [
        //   { required: true, message: "请选择证件类型", trigger: "change" },
        // ],

        // cardNo: [
        //   { required: true, message: "请输入证件号码", trigger: "blur" },
        //   { validator: cardNoCheck, trigger: "blur" },
        // ],
        // linkPhone: [
        //   { required: true, message: "请输入联系电话", trigger: "blur" },
        //   { validator: phoneCheck, trigger: "blur" },
        // ],
        // age: [{ required: true, message: "请输入年龄", trigger: "blur" }],
        // currentAddressCode: [
        //   { required: true, message: "请输入现住地", trigger: "change" },
        // ],
        currentAddressDetail: [
          { required: true, message: "请输入现住地详细地址", trigger: "blur" },
        ],
        // crowdTypeName: [
        //   { required: true, message: "请选择人群分类", trigger: "change" },
        // ],
        crowdType: [
          { required: true, message: "请选择人群分类", trigger: "change" },
        ],

        surveyObjectBelongAreaCode: [
          {
            required: true,
            message: "请选择调查对象所属地区",
            trigger: "change",
          },
        ],
        infoType: [
          { required: true, message: "请选择信息类别", trigger: "change" },
        ],
        surveyObjectBelongOrg: [
          { required: true, message: "请选择负责机构", trigger: "change" },
        ],
      },
      //3级
      props: {
        lazy: true,
        lazyLoad(node, resolve) {
          // console.log(node, '输出node，node00000')
          if (node.level == 0) {
            let cities = JSON.parse(localStorage.getItem("dqlist"));
            setTimeout(() => {
              resolve(cities);
            }, 0);
          } else {
            let formm = { label: node.data.value };
            http({
              method: "post",
              url: `/ohealth/api/v1/system/common/subList`,
              data: formm,
            }).then(function (response) {
              let list = response.data.data;
              const nodes = list.map((item) => ({
                value: item.value,
                label: item.label,
                leaf: item.leaf == 3,
              }));
              resolve(nodes);
            });
          }
        },
      },
      //4级
      arearProps: {
        lazy: true,
        lazyLoad(node, resolve) {
          // console.log(node,resolve,'输出node，resolve1111111111')
          if (node.level == 0) {
            let cities = JSON.parse(localStorage.getItem("dqlist"));
            setTimeout(() => {
              resolve(cities);
            }, 1000);
          } else {
            let formm = { label: node.data.value };
            // console.log(formm,'输出formm22222222222222')
            http({
              method: "post",
              url: `/ohealth/api/v1/system/common/subList`,
              data: formm,
            }).then(function (response) {
              let list = response.data.data;
              const nodes = list.map((item) => ({
                value: item.value,
                label: item.label,
                leaf: item.leaf == 4,
              }));
              resolve(nodes);
            });
          }
        },
      },
    };
  },
  // watch: {
  //   "regitsterInfo.currentAddressDetail"() {
  //     this.getBmap();
  //   },
  //   "regitsterInfo.currentAddress"() {
  //     this.getBmap();
  //   },
  // },
  created() {
    //常量
    this.surveyResult = SURVEY_RESULT;
    this.getEventList(); //获取事件名称
    this.HomeOrgList(); //查询居家隔离和排除的机构列表
    this.TransOrgList(); //查询转运任务业务机构列表
    this.getCrowdTypeList(); //获取人群分类
    this.getUserListByPeopleType(); //获取调查人员
    // console.log(JSON.parse(localStorage.getItem("addressThreeCode")),'从登陆页面进来的本地的三级编码')
    this.regitsterInfo.taskSourceCode = JSON.parse(localStorage.getItem("addressThreeCode")).split(',')
    this.regitsterInfo.taskSource = JSON.parse(localStorage.getItem("addressThreeDetail"))
  },
  mounted() {
    // this.threeAddress()
    // console.log( this.$store.state.defaultAddress, ' this.$store.state.defaultAddress')
  },
  methods: {

    threeAddress(){
      this.regitsterInfo.taskSourceCode = this.$store.state.defaultAddress.addressThreeCode?this.$store.state.defaultAddress.addressThreeCode.split(','):[];//三级任务来源地区
    this.regitsterInfo.taskSource = this.$store.state.defaultAddress.addressThreeDetail;//三级 任务来源所属地区
    },

    // async getThreeAddressCode() {
    //   let configkey = "default_province_urban_code";
    //   let result = await configKey(configkey);
    //   if (result.data.code == 200) {
    //     console.log(result)
    //     let arr = result.data.data.split(',');
    //     this.regitsterInfo.taskSourceCode = arr;
    //     console.log(this.regitsterInfo.taskSourceCode)
    //   }
    // },

    // async getThreeAddressDetail() {
    //   let configkey = "default_province_urban_name";
    //   let result = await configKey(configkey);
    //   if (result.data.code == 200) {
    //     this.regitsterInfo.taskSource = result.data.data;
    //     console.log(this.regitsterInfo.taskSource,'文字描述')
    //   }
    // },



    //选择任务来源
    handleTaskSource() {
      let node = this.$refs.taskSourceRef.getCheckedNodes()[0];
      if (node) {
        let taskSource = node.pathLabels.toString();
        this.regitsterInfo.taskSource = taskSource;
      }
    },
    //获取事件列表
    async getEventList(val) {
      const params = {};
      const { data } = await getEventListApi({ params });
      if (data.code == "200") {
        this.eventNameSelect = data.data;
      }
    },

    // 获取调查场所
    async ldcs(v) {
      if (this.regitsterInfo.eventId) {
        this.regitsterInfo.eventName = v.eventName;
      }
      let params = { eventId: v.id };
      const { data } = await getPlaceList({ params });

      if (data.code == "200") {
        this.eventcsSelect = data.data;
      }
    },
    // 当事件名称变化时，清空调查场所
    issueName() {
      this.regitsterInfo.surveyAddress = "";
    },
    //上传文件大小限制
    beforeUpload(file) {
      let size = file.size / 1024 / 1024 < 20;
      if (!size) {
        this.$message.warning("上传文件大小不能超过20M");
        return false;
      }
    },
    //文件上传成功
    handelSuccess(res, file, fileList) {
      if (res.code == 200) {
        this.$message({
          message: "上传成功",
          type: "success",
        });
        this.tempFileList.push({
          name: file.response.data.originalName,
          url: file.response.data.imgUrl,
        });
      }
    },
    //文件上传失败
    handelError(error) {
      if (res.code == 200) {
        this.$message({
          message: "上传失败",
          type: "error",
        });
      }
    },
    //文件删除询问
    beforeRemove() {
      return this.$confirm("确定要删除文件吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      });
    },
    //文件删除
    handleRemove(file) {
      if (file.status != "success") return;
      const deleteIndex = this.tempFileList.findIndex((item) => {
        if (file.response) {
          return item.url == file.response.data.imgUrl;
        } else {
          return item.url == file.url;
        }
      });
      this.tempFileList.splice(deleteIndex, 1);
    },
    //文件下载
    handlePreview(file) {
      let loadingInstance = Loading.service();
      Loading.service();
      if (file.status != "success") return;
      let fileUrl;
      if (file.response) {
        fileUrl = file.response.data.imgUrl;
      } else {
        fileUrl = file.url;
      }
      //获取文件后缀
      const suffix = file.name.substring(file.name.lastIndexOf(".") + 1);
      if (["png", "jpg"].includes(suffix)) {
        let alink = document.createElement("a");
        alink.href = fileUrl;
        alink.target = "_blank";
        alink.download = file.name; //fileName保存提示中用作预先填写的文件名
        alink.click();
      } else if (["docx", "doc", "pdf", "xls", "xlsx"].includes(suffix)) {
        importUrlFile(fileUrl, file.name);
      } else {
        return;
      }
      setTimeout(() => {
        loadingInstance.close();
      }, 1000);
    },

    //获取调查人员
    async getUserListByPeopleType() {
      const params = { peopleType: 4 };
      const { data } = await getUserListByPeopleTypeApi({ params });
      if (data.code == "200") {
        this.surveyUserNameSelect = data.data;
      }
    },
    //选择调查人员
    handleSurveyUserName(item) {
      this.regitsterInfo.surveyUserName = item.name;
      this.regitsterInfo.surveyUserId = item.id;
    },

    // 获取组织架构列表
    async getOrgQueryList() {
      const { data } = await getMedicalOrgList();
      if (data.code == "200") {
        this.surveyObjectBelongOrgSelect = data.data;
      }
    },

    //选择主要人群分类（单选）
    handelCrowdType(item) {
      this.regitsterInfo.otherCrowdTypeName = '';//清空
      this.regitsterInfo.crowdTypeId = item.id;
      this.regitsterInfo.crowdType = item.code;
      this.regitsterInfo.crowdTypeName = item.ruleName;
    },

    // 选择次要人群分类（多选数组）
    selectchange(event) {
      let arr = [];
      for (let item1 in event) {
        for (let item2 in this.typepopSelect) {
          if (event[item1] == this.typepopSelect[item2].id) {
            arr.push(this.typepopSelect[item2]);
          }
        }
      }
      this.regitsterInfo.secondaryCrowdTypes = arr.map((item) => {
        return {
          crowdTypeId: item.id,
          crowdTypeName: item.ruleName,
          crowdType: item.code,
        };
      }); //更改数组里面对象的key
    },

    //选择所属社区
    handelSurveyObjectBelongOrg(value) {
      this.regitsterInfo.surveyObjectBelongOrg = value.orgName;
      this.regitsterInfo.surveyObjectBelongOrgCode = value.orgCode;
    },

    // // 身份证计算
    // idCodeCount(id) {
    //   if (id && id.length == 18) {
    //     let obj = getIdNumberInfo(id);
    //     this.regitsterInfo.sex = obj.sex == 0 ? "1" : "0";
    //     this.regitsterInfo.age = obj.age;
    //   } else {
    //     this.regitsterInfo.sex = "";
    //     this.regitsterInfo.age = "";
    //   }
    // },

    // 流掉结果为居家隔离或者排除时候 （清空表单）
    ishome(v) {
      if (
        this.regitsterInfo.surveyResult == 0 ||
        this.regitsterInfo.surveyResult == 2 ||
        this.regitsterInfo.surveyResult == 3
      ) {
        this.regitsterInfo.surveyObjectBelongOrg = "";
      }
      this.regitsterInfo.otherResult = "";
      // this.regitsterInfo.glrz = "";
    },

    // 根据业务状态查询业务机构列表(查询居家隔离和排除的机构列表)jzy
    async HomeOrgList() {
      let params = { homeIsolation: 1 };
      let { data } = await getBusinessOrgList({ params });
      if (data.code == 200) {
        this.homeOrgList = data.data;
      }
      // console.log(this.homeOrgList)
    },

    // 根据业务状态查询业务机构列表(集中隔离时，查询转运任务业务机构列表)jzy
    async TransOrgList() {
      let params = { transshipmentTask: 1 };
      let { data } = await getBusinessOrgList({ params });
      if (data.code == 200) {
        this.concentrateOrgList = data.data;
      }
      // console.log(this.homeOrgList)
    },
    //  （人群分类）
    async getCrowdTypeList() {
      const { data } = await getCrowdTypeList();
      if (data.code == "200") {
        this.typepopSelect = data.data;
      }
    },

    // 次密选择可能性最大，阳性取消 分别清空对方的
    father() {
      this.$refs.positiveCaseRef &&
        this.$refs.positiveCaseRef.onenmbfyc("1234");
    },
    // 阳性选择可能性最大，次密取消 分别清空对方的
    father102() {
      this.$refs.closeContactRef && this.$refs.closeContactRef.onenmbfyc("123");
    },
    // 获取提交参数
    getFormData() {
      const joinArray = (arr) => {
        if (arr && Array.isArray(arr)) {
          return arr.join(',');
        }

        return '';
      }

      const formatDate = (date) => {
        if (date) {
          return this.$moment(date).format("YYYY-MM-DD HH:mm:ss")
        }
        return '';
      }
      let { crowdType } = this.regitsterInfo;
      let relationList = [];
      let survey = {...this.regitsterInfo };

      // 人群分类为7 次密接的时候
      if (crowdType == 7) {
        this.$refs.closeContactRef.commData();
        this.$refs.positiveCaseRef.commData();

        relationList = [
          ...this.$refs.positiveCaseRef.positiveCaseInfo,
          ...this.$refs.closeContactRef.closeContactInfo,
        ];
      } else {
        // 人群分类为密接 4 情况下
        if(crowdType==4){
          // const allLastTimeFlag = this.$refs.positiveCaseRef.positiveCaseInfo.every(o => !!o.lastContactTime);
          // if (!allLastTimeFlag) {
          //   this.$message({
          //     message: '请选择最后接触时间',
          //     type: 'error'
          //   })
          //   return;
          // }
        }
        
        this.$refs.positiveCaseRef.commData();

        relationList = this.$refs.positiveCaseRef.positiveCaseInfo;
      }
      survey.surveyDate = formatDate(survey.surveyDate);

      survey.taskSourceCode = survey.taskSourceCode.join(',');
      let surveyObjectList = (this.$refs.residentBasicInfoRef.userListForm.userList || []).map(o => ({
        ...o,
        currentAddressCode: joinArray(o.currentAddressCode),
        surveyObjectBelongAreaCode: joinArray(o.surveyObjectBelongAreaCode),
        _id:undefined
      }));



      let surveyRelationList = relationList.map(o => ({
        ...o,
        dateOfOnset: formatDate(o.dateOfOnset),
        firstContactTime: formatDate(o.firstContactTime),
        lastContactTime: formatDate(o.lastContactTime),
        relationAddressCode: joinArray(o.relationAddressCode),
        probabilityRelation: o.probabilityRelation ? 1 : 0,
        sort: o.sort || 1
      }));




      // if (this.$refs.positiveCaseRef) {
        
      // }
      // //  如果人群分类 为密切解除医学观察，code==4，并且阳性的关联至少有一个，那么最后接触时间必填
      // const postiviVlidateAgain = await Promise.all(this.$refs.positiveCaseRef.comm());
      // // 不等于数组代表，至少有一个添加的阳性tab页，
      // if(this.regitsterInfo.crowdType == 4&&!Array.isArray(postiviVlidateAgain)){

      // }



      return {
        survey,
        surveyObjectList,
        surveyRelationList,
        files: this.tempFileList
      }
      
    },
    closeDialog() {
      this.dialogVisible = false;
      this.submitLoading = false;
    },
    async checkIsReg() {
      let { crowdType } = this.regitsterInfo;

      let params = this.getFormData()

      if(crowdType==4){
          const allLastTimeFlag = this.$refs.positiveCaseRef.positiveCaseInfo.every(o => !!o.lastContactTime);
          if (!allLastTimeFlag) {
            this.$message({
              message: '请选择最后接触时间',
              type: 'error'
            })
            return;
          }
        }

      const res = await batchCheckIsReg(params)
      // console.log(res,'res打印ressss打印查重的返回整个')
      
      
      if (res.data.data == null) {
        // this.submitLoading = false;
        // 没有重复人员，直接调用保存提交

        this.postSubmit()
      } else if (this.dialogVisible == false) {
        //打开警告框
        this.dialogVisible = true;
        this.dialogMessage = res.data.data;
        // this.isReg = res.data.data;
      } else if (this.dialogVisible == true) {

        // this.type == "edit" ? this.updateSurveyInfo() : this.saveSurveyInfo();
        // this.dialogVisible == false;
      }

    },



    // 提交请求
    async postSubmit(){
      this.continueSubmitLoading = true
      let params = this.getFormData();
      // console.log(params,'打印params')
      let result = await saveSurveyInfoList(params)
      // console.log(result,'result。。。。。')
      if(result.status==200){
        this.submitLoading = false
        this.continueSubmitLoading = false;
        this.dialogVisible = false;
        if(result.data.code=='200'){
          this.$message({
          message: result.data.message,
          type: "success",
        });
        // window.location.reload(); 
        this.$router.go(0)

        }
      }
    },



    
    // 提交按钮
    async submitForm(){
      let flag = true;
      try {
        // console.log('打印提交btn')
        await this.$refs.regitsterInfoForm.validate();
        const postiviRef = this.$refs.positiveCaseRef;
        // console.log(postiviRef, 'postiviRef');
        
        const closeRef = this.$refs.closeContactRef;
        const crowdType = this.regitsterInfo.crowdType;
        // console.log(crowdType, 'crowdType');
        const residentRef = this.$refs.residentBasicInfoRef;

        // if (postiviRef && crowdType &&  postiviRef.comm().length) {
        //   const postiviVlidate = await Promise.all(postiviRef.comm());
        //   if (!postiviVlidate) {
        //     throw new Error('阳性必填项未填写或填写格式不符合')
        //   }
        // }

        if (crowdType == 4) {
          if (postiviRef) {
            const postiviVlidate = await Promise.all(postiviRef.comm());
            if(Array.isArray(postiviVlidate)){
              if(postiviVlidate.length == 0){
                throw new Error('关联密切接触者（阳性）必须添加一个')
              }
            }else if(!postiviVlidate){
              throw new Error('阳性必填项未填写或填写格式不符合')
            }
          }
        }


        // if (crowdType == 7) {
        //   // console.log(closeRef.comm(),'打印一密的(closeRef.comm()')

        //   if (closeRef) {
        //     const closeVlidate = await Promise.all(closeRef.comm());
        //   if (!closeVlidate) {
        //     throw new Error('一密必填项未填写或填写格式不符合')
        //   }
        //   } else {
        //     throw new Error('关联密切接触者（一密）必须添加一个')
        //   }
        // }

        if (crowdType == 7) {
          if (closeRef) {
            const closeVlidate = await Promise.all(closeRef.comm());
            if(Array.isArray(closeVlidate)){
              if(closeVlidate.length == 0){
                throw new Error('关联密切接触者（一密）必须添加一个')
              }
            }else if(!closeVlidate){
              throw new Error('一密必填项未填写或填写格式不符合')
            }
          }
        }


        const residentRes =  await  residentRef.comm();
        if (!residentRes) {
          return;
        }

      } catch(e) {
        // console.log(e, 'e')
        this.$message({
          message: e.message || "必填项未填写或填写格式不符合",
          type: "warning",
          offset: 60,
        });
        // 有错误应该return掉
        flag = false;
        return;
      }
      // console.log(flag, 'flag')
      if (flag) {
        this.submitLoading = true;
        this.checkIsReg();
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
  height: 100%;

  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .top_left {
      font-size: 18px;
      font-weight: bolder;
      color: #666666;
    }

    .top_right {
      font-size: 12px;
      color: #9a9a9a;

      .xinxin {
        color: #ff5050;
      }
    }
  }
}

.title {
  display: flex;
  align-items: center;
  margin-bottom: 14px;
}

.blue {
  width: 4px;
  height: 20px;
  background-color: #3476f5;
  margin-right: 6px;
}

.content-item {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.content-item1 {
  width: 100%;
  height: 100%;
}

.special {
  flex: 1;
}

// .special1 {}

.formitem {
  width: 50%;
}

.select-width {
  width: 80%;
}

.select-width2 {
  width: 400px;
  margin-right: 80px;
}

// .btns {
//   display: flex;
//   justify-content: center;
// }

.warning {
  margin-bottom: 20px;
  padding-left: 20px;
}

.ph {
  white-space: nowrap;
  display: flex;
  align-items: center;
  height: 40px;
}

.classify {
  white-space: nowrap;
  display: flex;
  justify-content: space-between;
}

.classify .select-width {
  width: 240px;
}

.classify .other-input {
  width: 200px;
}

.other-input {
  margin-left: 10px;
}

.el-icon-warning-outline {
  color: #ff9900;
  font-size: 26px;
  font-weight: bold;
  margin-right: 10px;
}
.notification {
  display: flex;
  flex-wrap: nowrap;
  width: 80%;
  margin: 20px auto;
  justify-content: space-around;
  .NameNotification {
    width: 12%;
  }
  .CardIdNotification {
    width: 35%;
  }

  .OrgPhoneNotification {
    width: 35%;
  }

  .TypeNotification {
    width: 20%;
  }
}

.btnbox{
  height: 50px;
}
.btns{
  position:fixed;
  margin:0 auto;
  left:0;
  right:0;
  height: 60px;
  bottom: 10px;
  width: 120px;
  z-index: 999;
}

</style>